import * as React from "react";


import GenericSelector from './GenericSelector';
import { useCompetitions } from '../../Context/CompetitionsContext';

interface IProps {
    presetid?: number | (() => number);
    handleSelect(s: any[]): void;
    inputProps?: any;
    size?: 'large' | 'lg' | 'small' | 'sm';
}

export default function CompetitionSelector(props: IProps) {
    const { visibleCompetitions } = useCompetitions();
    //Log(`CompetitionsSelector with `,props, `visible comps `, visibleCompetitions());
    return (
            <div>
                <GenericSelector
                    {...props}
                    options={visibleCompetitions()}
                    useOptions
                    preset={[]}
                    presetid={(typeof props.presetid === 'function') ? props.presetid() : props.presetid}
                    displayField={(opt: any): string => {                       
                        return `${opt.Title}`
                    }}
                    idField='ID'
                    placeholder='Select Competition...'
                />
        </div>
    );

}
