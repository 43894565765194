import * as React from "react";
import {  useState, useEffect, createContext,  ReactNode } from 'react';
import { useAuth } from "../Auth/AuthProvider";
import { API_ENDPOINTS, API_URL } from "../Globals";
import { post, retrieveItems } from "../services/FetchWrapper";
import { useCompetitions } from "./CompetitionsContext";
import { useErrorHandler } from "react-error-boundary";
// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
import { IWind } from "ladderscoring";



export interface dbWind {
    CompetitionID: number;
    Date: string;       //ISO Date
    WindStrength: number;
    WindDirection: number;
}
export interface WindsContextData {
    winds: dbWind[];
    findWind: (compid: number | undefined, date: string) => IWind | undefined;
    saveWind: (compid: number, date: string, wind:IWind) => void;
}


const WindsContext = createContext<WindsContextData>(
    {
        winds: [],
        findWind: () => undefined,
        saveWind: () => undefined,
    });

const ENDPOINT = API_ENDPOINTS.WINDS;

export const WindsProvider = ({ children }: { children: ReactNode }) => {

    const [winds, setWinds] = useState<dbWind[]>([]);

    const auth = useAuth();  
    const {selectedCompetition} = useCompetitions();
    const handleError = useErrorHandler()
    useEffect(() => {

        const getWinds = async () => {

            let compid = selectedCompetition?.ID ?? '';
            //Log(`getWinds for ${compid}`)
            //let userid = auth?.user?.userDetails?.userid ?? '';
            let token = auth?.user?.token ?? null;
            if (compid !== '') {
                //Log(`getWinds from ${API_URL}/${ENDPOINT}/${compid}`)

                retrieveItems<dbWind>(`${API_URL}/${ENDPOINT}/${compid}`, '', true,token)
                .then( async (items) =>  {
                    if (items) {                  
                        setWinds(items);
                    }
                })
                .catch(e=>{
                    LogError(`getWinds: ${(e as Error).message}`)
                    handleError(new Error(`getWinds error ${(e as Error).message}`))
                })             
            }
            else {
                // not logged in...
                setWinds([]);
            }
        }
        getWinds()
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user, selectedCompetition]);


    const findWind = (compid: number | undefined, date: string):IWind|undefined => {
        let wind = winds.find(w=>w.CompetitionID===compid && w.Date===date)
        return wind ? {winddirection: wind.WindDirection, windstrength: wind.WindStrength} : undefined;
    }

    const saveWind = async (compid: number, date: string, wind:IWind) => {
        if (compid !== 0 && wind) {
            let dbw:dbWind = {CompetitionID: compid, Date: date, WindDirection: wind.winddirection, WindStrength: wind.windstrength}
            let newwinds = [...winds];
            let idx = winds.findIndex(w=>w.CompetitionID===compid && w.Date===date);
            if (idx !== -1) {
                // we have existing data to overwrite
                newwinds[idx].WindDirection=dbw.WindDirection;
                newwinds[idx].WindStrength=dbw.WindStrength;
            }
            else {
                // add a new entry
                newwinds.push(dbw);
            }
            setWinds(newwinds);
            return post<dbWind>(`${API_URL}/${API_ENDPOINTS.WINDS}`, dbw,auth?.user?.token)
        }
    }

    return (
        <WindsContext.Provider value={
            {
                winds: winds,
                findWind: findWind,
                saveWind: saveWind,

            }}>
            {children}
        </WindsContext.Provider>
    )
}

export function useWindsContext() {
    const context = React.useContext(WindsContext);
    if (context === undefined) {
        throw new Error("useWindsContext must be used within a WindsProvider");
    }
    return context;
}

export const useWinds = (): WindsContextData=> {
    const context = useWindsContext();
    return context;
}
export const CompetitorsConsumer = WindsContext.Consumer;

export default WindsContext;