import * as  React from "react";
import { useState, useEffect, } from 'react'
import { Button } from 'reactstrap';
import getPropValue from "./GetPropValue";

import DraggableModal from "./DraggableModal";

export enum DialogType {
    primary = 'bg-primary',
    secondary = 'bg-secondary',
    success = 'bg-success',
    danger = 'bg-danger',
    warning = 'bg-warning',
    info = 'bg-info',
    light = 'bg-light',
    dark = 'bg-dark',
    white = 'bg-white'
}
export enum DialogSize {
    small = 'sm',
    large = 'lg',
    xlarge = 'xl',
}
interface IProps {
    title?: string;
    content?: string | (() => string);
    type?: DialogType;
    show: boolean;
    yesLabel?: string;
    noLabel?: string;
    handleYes?: (() => void);
    handleNo?: (() => void);
    handleClose?: (() => void);
    closeTimeout?: number;
}

const ConfirmModal: React.FC<React.PropsWithChildren<IProps>> = (props) => {

    const [show, setShow] = useState(props.show);
    useEffect(() => { setShow(props.show) }, [props.show]);
    const doClose = () => {
        setShow(false);
        if (props.handleClose) props.handleClose();
    }

    function createMarkup(html: string) {
        return { __html: html };
    }
    return (
        <DraggableModal 
        key='ShowConfirmModal'
        show={show}
            onClose={doClose}
            onOpen={
                () => {
                    // if a timeout was specified, close afetr that time
                    if (props.closeTimeout && props.closeTimeout > 0) {
                        setTimeout(() => {
                            doClose();
                        }, props.closeTimeout * 1000)
                    }
                }                
            }
            header={<span>{getPropValue(props.title ?? 'No Title')}</span>}
            headerclass={props.type ?? DialogType.info}
            body={<div dangerouslySetInnerHTML={createMarkup(getPropValue(props.content ?? 'No Content'))} />}
            footer={
                <>
                {props.yesLabel!=='' ?
                <Button variant="secondary"
                    onClick={() => {
                        if (props.handleYes) props.handleYes();
                        doClose();
                    }}>
                    {props.yesLabel ?? 'Yes'}
                </Button>
                : ''}


                {props.noLabel !== '' ?
                    <Button variant="secondary"
                        onClick={() => {
                            if (props.handleNo) props.handleNo();
                            doClose();
                        }}>
                        {props.noLabel ?? 'No'}
                    </Button>
                    : ''}
                </>
            }
        />
    )
}
export default ConfirmModal;