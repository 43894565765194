import { current, produce } from 'immer';
import {   useState } from 'react';

import { DateTime } from 'luxon';
import { Utilities } from '../lib/common';

import {  AssessIGCResult, IScoringConfig, IWind, scoreFlight, ScoreIGCResult, TaskModel } from 'ladderscoring';


import { ICompetitor } from './Competitor';

import { IResult } from '../components/Pages/Scoring/Scoring';
import { deleteItem, post } from '../services/FetchWrapper';
import { API_ENDPOINTS, API_URL } from '../Globals';
import { IToken } from '../Auth/Auth';
// eslint-disable-next-line
import { Log, LogError } from '../services/Logging';
import { IPenalty } from '../components/Utility/GridComponents';

export interface dbFlight extends Record<string, any>{
    ID: number;
    CompetitorID:number;
    CompetitionID: number;
    FlightDate: string; //ISO Date
    TaskID: number;
    LogfileName?: string;    
    StartTime?: string;
    FinishTime?:string;
    Speed?:number;
    HCSpeed?: number;
    Distance?: number;
    HCDistance?: number;
    DistancePoints?:number;
    SpeedPoints?:number;
    PenaltyPoints?:number;
    PenaltyNotes?:string
    TotalPoints?:number;
}

export interface dbLogFile {
    FlightID: number;
    Contents: string;
}

export interface ILogFile extends Record<string, any> {
    Name?: string;
    Contents?: string;
    Date?: string;
}

export interface IFlight extends Record<string, any>{
    ID?: number ;             
    FlightDate: string ;
    CompetitionID: number ;
    CompetitorID:  number;     // the id in the Competitors list    
    LogFile?: ILogFile;
    TaskID?:  number ;         // ID of the Task to use for this flight
    AnalysisResult?: AssessIGCResult;
    ScoreResult?: ScoreIGCResult ;
    ManualScore?: boolean;
}

export enum FlightActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    UPDATE_DATE = 'UPDATE_DATE',
    UPDATE_LOGFILE = 'UPDATE_LOGFILE',
    SETLOGFILECONTENTS = 'SETLOGFILECONTENTS',
    UPDATE_COMPETITOR = 'UPDATE_COMPETITOR',
    UPDATE_TASK = 'UPDATE_TASK',
    UPDATE_ANALYSIS = 'UPDATE_ANALYSIS', 
    UPDATE_ANALYSIS_SINGLE = 'UPDATE_ANALYSIS_SINGLE', 

    UPDATE_ROW = 'UPDATE_ROW',
    UPDATE_SCORE = 'UPDATE_SCORE',
    CLEAR_ANALYSIS = 'CLEAR_ANALYSIS',
    SAVE_ANALYSIS = 'SAVE_ANALYSIS',
    CLEAR = 'CLEAR',
    LOAD = 'LOAD',
    SAVE = 'SAVE',
    SORT = 'SORT',

}

export type FlightAction = {
    type: FlightActionType;
    payload?: any;
}

const emptyScoreResult:ScoreIGCResult = {
    taskDistance: 0,
    hcDistance:0,
    scoreDistance:0,
    completed:false,
    taskTime:0,
    hcSpeed:0,
    distancePoints:0,
    speedPoints:0,
    penaltyPoints:0,
    totalPoints:0,
    errors:[]
}
const emptyAnalysisResult:AssessIGCResult = {
    errorArray: [],
    usedTPs:  [],
    missingTPs: [],

    taskCompleted: false,
    totalScoringDistance: 0,
    legScoringDistance: [],
    speed:0,                
    flightException: false,
    flightLoaded: false,
    gpsLandout: false,
    landoutPosition: null,
    loggerFlightDate: null,
    flightDate: null,
    secondPilot: null,
    gliderReg: '',
    gliderType: null,
    timeTaken: 0,
    heightLoss: 0,
    StartTime: undefined,
    FinishTime: undefined,
    LandingTime: undefined,
    TPTimes: [],
    assessment: null,

}

export const useFlightReducer = (initState: IFlight[]) => {
    const [state, setState] = useState(initState);

    const dispatch = async (action: FlightAction) => {
        let newState = await flightReducer(state, action);
        setState(newState);
    }
    return [state, dispatch] as const;   // need the 'as const' to get Typescript inferencing working
}

export const makedbFlight = (flt:IFlight):dbFlight => {
    return {
        ID: (flt.ID ?? 0) as number,
        CompetitorID: (flt.CompetitorID ??0) as number,
        CompetitionID: (flt.CompetitionID ?? 0) as number,
        FlightDate: flt.FlightDate ?? '', //ISO Date
        TaskID: (flt.TaskID ??0) as number,
        LogfileName: flt.LogFile?.Name  ,
        StartTime: flt.AnalysisResult?.StartTime,
        FinishTime: flt.AnalysisResult?.FinishTime,
        Speed: flt.AnalysisResult?.speed,
        HCSpeed:flt.ScoreResult?.hcSpeed,
        Distance: flt.AnalysisResult?.totalScoringDistance,
        HCDistance: flt.ScoreResult?.hcDistance,
        DistancePoints:flt.ScoreResult ? Math.round(flt.ScoreResult.distancePoints) : undefined,
        SpeedPoints:flt.ScoreResult ? Math.round(flt.ScoreResult.speedPoints): undefined,
        PenaltyPoints: flt.ScoreResult ? flt.ScoreResult.penaltyPoints : undefined,
        TotalPoints: flt.ScoreResult ? Math.round(flt.ScoreResult.totalPoints) : undefined,
        PenaltyNotes: flt.ScoreResult ? flt.ScoreResult.penaltyNotes : undefined,
    }

}
export const fromdbFlight = (flt:dbFlight):IFlight => {
    return {

        ID:flt.ID,
        FlightDate:flt.FlightDate,
        CompetitorID: flt.CompetitorID,     // the id in the Competitors list
        CompetitionID: flt.CompetitionID,
        TaskID: flt.TaskID,         // ID of the Task to use for this flight
        AnalysisResult:undefined,
        ScoreResult: undefined,                                    
        LogFile:{ 
            Name: flt.LogfileName,
            Contents: undefined,
            Date: flt.FlightDate,
        }         
    };

}
const postFlight = async (flt:dbFlight, token:IToken) => {
    if (flt.CompetitorID!==0 && flt.TaskID !== 0) {
        return post<IFlight>(`${API_URL}/${API_ENDPOINTS.SAVEFLIGHT}`, flt, token)
        .catch(async error => {
            LogError(`Flight Update error ${error}`)
        })
    }
}
const postLogfile = async (log:dbLogFile, token:IToken) => {
    return post<dbLogFile>(`${API_URL}/${API_ENDPOINTS.SAVELOGFILE}`, log, token)
    .catch(async error => {
        LogError(`Logfile Update error ${error}`)
    })
}

const deleteFlight = async (flightid: string|number|undefined , token:IToken) => {
    if (flightid) {
    return deleteItem<IFlight>(`${API_URL}/${API_ENDPOINTS.DELETEFLIGHT}/${flightid}`, token)
    .catch(async error => {
        LogError(`Flight Delete error ${error}`)
    })
    }
}

const sortResults = (flts: IFlight[]) => {
    flts.sort((a, b) => {
       return  (b.ScoreResult?.totalPoints ?? 0) - (a.ScoreResult?.totalPoints ?? 0)
    })
}

const saveAnalysis = (res:IResult, config: IScoringConfig, draftFlights:IFlight[])=>{

    Log(`saveAnalysis: `, res)
    let id: string | number | undefined = res.FlightID;
    let analysis:AssessIGCResult | undefined = res.Analysis;
    let score: ScoreIGCResult | undefined = res.Score;

    let idx = draftFlights.findIndex(flt => { return flt.ID === id });
    if (idx !== -1) {

        draftFlights[idx].AnalysisResult = analysis;
        // We've done an auto analysis
        draftFlights[idx].ManualScore=false;
        // Preserve any Penalty Points...        
        const pp=draftFlights[idx].ScoreResult?.penaltyPoints;
        const pn=draftFlights[idx].ScoreResult?.penaltyNotes;
        draftFlights[idx].ScoreResult = score;
        
        if (draftFlights[idx].ScoreResult !==undefined && pp!==undefined)  {
            const result = draftFlights[idx].ScoreResult as ScoreIGCResult
            result.penaltyPoints=pp;
            result.penaltyNotes=pn;
            result.totalPoints=(getTotalPoints(result,config) ?? 0)
        }
    }
    
    // now sort
    sortResults(draftFlights);

}

const getTotalPoints = (result: ScoreIGCResult | undefined, config:IScoringConfig)=>{
    const total =  result ?
        (result.distancePoints+result.speedPoints) * (result.completed ? config.CompletionFactor : 1) - result.penaltyPoints
        :
        undefined
    return total
}
const rescoreFlight = async (flt:IFlight, wind:IWind, handicap: number, config:IScoringConfig)=>{
    // Recalculate flight scores following a manual edit.
    // Recalc the Time Taken then rescore. Need the wind & glider details
    Log(`rescoreFlight: `, current(flt))

    if (flt.AnalysisResult?.task) {
        // update the time take in case the start/finish/landing times were updated...        
        if (flt.AnalysisResult.StartTime && (flt.AnalysisResult.FinishTime || flt.AnalysisResult.LandingTime)) {
            const starttime = DateTime.fromISO(flt.AnalysisResult.StartTime)
            const finishtimestring = flt.AnalysisResult.FinishTime ? flt.AnalysisResult.FinishTime : flt.AnalysisResult.LandingTime

            if (finishtimestring) {
                const finishtime = DateTime.fromISO(finishtimestring)
                 Log(`rescoreFlight: finishtime is ${finishtime}, time taken is ${finishtime.diff(starttime,'second').seconds}`)
                
                flt.AnalysisResult.timeTaken = finishtime.diff(starttime,'second').seconds
                flt.AnalysisResult.speed =  flt.AnalysisResult.totalScoringDistance*3600/flt.AnalysisResult.timeTaken;
                
            }
        }
        
        const pp = flt.ScoreResult?.penaltyPoints ?? 0;
        flt.ScoreResult = await scoreFlight(flt.AnalysisResult.task, flt.AnalysisResult,wind,handicap,config);
        if (flt.ScoreResult) {
            flt.ScoreResult.penaltyPoints=pp;
            flt.ScoreResult.totalPoints = getTotalPoints(flt.ScoreResult, config) ?? 0
        }
    }
}



export async function flightReducer(flights: IFlight[], action: FlightAction): Promise<IFlight[]> {    
    return  await produce(flights, async (draftFlights) => {
        Log(`flightReducer: action ${action.type}, payload `, action.payload)
        switch (action.type) {

            case FlightActionType.INSERT:
                // payload is {value: flt, token :token}

                let flt:IFlight = action.payload.value;

                await postFlight(makedbFlight(flt), action.payload.token)
                    .then(async resp=> {
                        if (resp) {
                            let newflt = resp.parsedBody       // this now has the correct ID
                            if (newflt) {
                                draftFlights.unshift({...flt, ID: newflt.ID});
                                // was there also a Log file?
                                if (flt.LogFile?.Contents && flt.LogFile.Contents.length> 0) {
                                    // post the log file too...
                                    let log:dbLogFile={FlightID: (newflt.ID ?? 0) as number, Contents: flt.LogFile.Contents as string } 
                                    await postLogfile(log, action.payload.token)
                                }
                            }
                        }
                    })

                break;

            case FlightActionType.DELETE:
                //payload is {FlightID: ID to delete}
                let idx = draftFlights.findIndex(f => { return f.ID === action.payload.FlightID })
                if (idx !== -1) {
                    deleteFlight((action.payload.FlightID), action.payload.token)
                    draftFlights.splice(idx, 1);
                }
                break;

            case FlightActionType.UPDATE_LOGFILE: {
                //payload is {ID: which to update, value: {name: string, contents: string with file contents}             
                let idx = draftFlights.findIndex(flt => {return flt.ID===action.payload.FlightID})
                if (idx !== -1) {
                    draftFlights[idx].LogFileName = action.payload.value.name;
                    draftFlights[idx].LogFileContents = action.payload.value.contents;
                    let dt = await Utilities.getFlightDateFromText(action.payload.value.contents);
                    draftFlights[idx].LogFile = {
                        Name: action.payload.value.name,
                        Contents: action.payload.value.contents,
                        Date: dt ? DateTime.fromJSDate(dt).toISODate()! : undefined,
                        }
                    let log:dbLogFile={FlightID: draftFlights[idx].ID as number, Contents: draftFlights[idx].LogFile?.Contents as string } 
                    await postLogfile(log, action.payload.token)
                    }
                break;
            }
            case FlightActionType.SETLOGFILECONTENTS: {
                let idx = draftFlights.findIndex(flt => {return flt.ID===action.payload.FlightID})
                if (idx !== -1) {
                    draftFlights[idx].LogFile = {...draftFlights[idx].LogFile, Contents: action.payload.Contents}
                }
                break;
            }
            case FlightActionType.UPDATE_DATE: {
                //payload is {FlightID: ID to  update, value: new date}

                let dt = DateTime.fromJSDate(action.payload.value);
                let idx = draftFlights.findIndex(f => { return f.ID === action.payload.FlightID })
                if (idx !== -1) {
                    draftFlights[idx].FlightDate = dt.toISODate()!;
                }
                break;
            }
            case FlightActionType.UPDATE_COMPETITOR: {
                //payload is {FlightID: which to update, value: competitor id
                let idx = draftFlights.findIndex(f => { return f.ID === action.payload.FlightID })
                if (idx !== -1) {
                    draftFlights[idx].Competitor = action.payload.value;
                }
                break;
            }

            case FlightActionType.CLEAR_ANALYSIS: {
                draftFlights.forEach(flt => {
                    // Preserve any Penalty Points that have been set...
                    if (flt.ScoreResult?.penaltyPoints !== undefined && flt.ScoreResult.penaltyPoints !== 0) {
                        const pp =  flt.ScoreResult.penaltyPoints;
                        flt.ScoreResult=emptyScoreResult;
                        flt.ScoreResult.penaltyPoints=pp;
                    }
                    else {
                        flt.ScoreResult = undefined;
                    }

                    flt.AnalysisResult = undefined;                    
                    flt.ManualScore=undefined;
                });
                break;
            }
            case FlightActionType.UPDATE_ANALYSIS_SINGLE: {
                //payload is value: { Result: IResult, config:IScoringConfig }

                saveAnalysis(action.payload.result as IResult, action.payload.config as IScoringConfig, draftFlights);
                break;
            }
            case FlightActionType.UPDATE_ANALYSIS: {
                //payload is value: [{ Result: IResult, config:  }]

                let results: IResult[] = action.payload.results;
                results.forEach(res => {
                    saveAnalysis(res, action.payload.config as IScoringConfig, draftFlights );
                })

                break;
            }

            case FlightActionType.SAVE_ANALYSIS: {
                //  payload is {CompetitionID: selectedCompetition.ID, date: date}, save the results back to the database
                let flts = draftFlights.filter((flt)=> {return (flt.FlightDate===action.payload.date 
                                                && flt.CompetitionID===action.payload.CompetitionID)});
                flts.forEach(async(flt)=>{
                    await postFlight(makedbFlight(flt), action.payload.token);
                });
                // Now update the last saved counter...
                await post<number>(`${API_URL}/${API_ENDPOINTS.RESULTSSAVED}`, action.payload.CompetitionID, action.payload.token)                
                break;
            }
            case FlightActionType.UPDATE_TASK:
            {
                //payload is {FlightID: which to update, TaskID: TaskID}:
                    let idx = draftFlights.findIndex(flt => { return flt.ID === action.payload.FlightID});
                    if (idx !== -1) {
                        draftFlights[idx].TaskID = action.payload.TaskID;
                    }
                break;
                }

            case FlightActionType.UPDATE_ROW: {
                //payload is {FlightID: ID, value{column:value }}
                // This is used from the Flights page when we are editting the flight details
                // If the update is to the Log File, we need to upload that to the server...
                let idx = draftFlights.findIndex(f => { return f.ID === action.payload.FlightID })
                if (idx !== -1) {
                    draftFlights[idx] = { ...draftFlights[idx], ...action.payload.value }
                    await postFlight(makedbFlight(draftFlights[idx]), action.payload.token)
                    // do we need to update the Log file too?
                    if (action.payload.value && action.payload.value.hasOwnProperty('LogFile')) {
                        let log:dbLogFile = {FlightID: draftFlights[idx].ID as number, Contents: action.payload.value.LogFile.Contents}
                        postLogfile(log, action.payload.token)
                    }
                }
                break;
            }
            case FlightActionType.UPDATE_SCORE: {
                //payload is {ID: changedID, value:changedvalue, token: auth?.user?.token, config: IScoringConfig}
                // Update of Score (manual scoring) from the scoring page...
                if (action.payload.value) {
                    let idx = draftFlights.findIndex(f => { return f.ID?.toString() === action.payload.ID })
                    if (idx !== -1) {
                        // here we need to update the Assessment & Scoring results...
                        // in AnalysisResult:  StartTime, FinishTime, speed, hcSpeed, totalScoringDistance, HCDistance, 
                        // in ScoringResult: distancePoints, speedPoints, penaltyPoints, totalPoints
                        let flt =  draftFlights[idx];
                        const scoringconfig:IScoringConfig = action.payload.config
                        const wind:IWind = action.payload.wind;
                        const handicap = action.payload.handicap

                        if (!flt.ScoreResult) flt.ScoreResult=emptyScoreResult;
                        if (!flt.AnalysisResult) flt.AnalysisResult=emptyAnalysisResult;

                        // action.payload.value is an object with properties of column name holding new values     

                            for (const [key, value] of Object.entries(action.payload.value)) {
                                Log(`UpdateScore: field ${key} has new value ${action.payload.value[key]}`);
                                switch (key) {
                                    case 'StartTime' : {
                                        flt.AnalysisResult.StartTime = value as string;
                                        if (value && value !== '') {
                                            rescoreFlight(flt,wind,handicap,scoringconfig );                                            
                                        }
                                        else {
                                            // Start Time was cleared - so no score
                                            flt.ScoreResult=emptyScoreResult
                                            flt.AnalysisResult = emptyAnalysisResult                                            
                                        }
                                        flt.ManualScore=true;                                        
                                        break;
                                    }
                                    case 'FinishTime' : {
                                        flt.AnalysisResult.FinishTime = value as string;
                                        if (value && value !== '') {
                                            rescoreFlight(flt,wind,handicap,scoringconfig );                                                                                        
                                        }
                                        else {
                                            //Finish time cleared - no Score
                                            flt.ScoreResult=emptyScoreResult
                                            flt.AnalysisResult = emptyAnalysisResult     
                                        }
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'speed' : {
                                        flt.AnalysisResult.speed = value as number;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'hcSpeed' : {
                                        flt.ScoreResult.hcSpeed = value as number;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'totalScoringDistance': {
                                        flt.AnalysisResult.totalScoringDistance=value as number;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'hcDistance': {
                                        flt.ScoreResult.hcDistance=value  as number;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'distancePoints' : {
                                        // Manual points, clear the rest
                                        flt.AnalysisResult=emptyAnalysisResult;
                                        flt.ScoreResult = {...flt.ScoreResult,
                                            distancePoints: value as number,
                                            hcDistance:0, 
                                            hcSpeed:0                                           
                                        }
                                        flt.ScoreResult.totalPoints = getTotalPoints(flt.ScoreResult, scoringconfig) ?? 0;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'speedPoints' : {
                                        // Manual points, clear the rest                                        
                                        flt.AnalysisResult=emptyAnalysisResult
                                        flt.ScoreResult = {...flt.ScoreResult,
                                            speedPoints: value as number,
                                            hcDistance:0,
                                            hcSpeed:0,                                            
                                        }
                                        flt.ScoreResult.totalPoints = getTotalPoints(flt.ScoreResult, scoringconfig) ?? 0;
                                        flt.ManualScore=true;
                                        break;
                                    }
                                    case 'penaltyPoints' : { 
                                        if (value) {
                                            const penalty = value as IPenalty
                                            Log(`UPDATE_SCORE: new penalty `, penalty)
                                            flt.ScoreResult.penaltyPoints=penalty.penaltyPoints ?? 0;
                                            flt.ScoreResult.penaltyNotes=penalty.penaltyNotes ?? '';
                                        }
                                        flt.ScoreResult.totalPoints = getTotalPoints(flt.ScoreResult, scoringconfig) ?? 0;
                                        break;
                                    }
                                    case 'totalPoints' : {
                                        // not permitted
                                        break;
                                    }
                                    default: {
                                        LogError(`UpdateScore: unknown key ${key}`)
                                    }
                                }

                                // we've updated the score, so re-sort...
                                sortResults(draftFlights);
                                // update the database
                                await postFlight(makedbFlight(flt), action.payload.token);                   
                            }
                        }
                }
                break;  
            }          
           
            case FlightActionType.LOAD: {
                // payload is {value: Array of Flights}
                draftFlights.length = 0;
                let list = action.payload.value as IFlight[];

                list.forEach(flt => {
                    draftFlights.push({
                        ID: flt.ID,
                        CompetitorID: flt.CompetitorID,
                        CompetitionID: flt.CompetitionID,
                        LogFile: flt.LogFile,
                        FlightDate: DateTime.fromISO(flt.FlightDate ?? '2000-01-01').toISODate()!,
                        TaskID: flt.TaskID,
                        AnalysisResult: flt.AnalysisResult ?? undefined,
                        ScoreResult: flt.ScoreResult ?? undefined,
                    });
                });
               
                break;
            }
            case FlightActionType.SAVE: {
                // write to DB
                    alert(`FlightActionType.SAVE: NYI`);
                break;
            }
            case FlightActionType.CLEAR: {
                draftFlights.length=0;
                break;
            }
            case FlightActionType.SORT: {
                //payload is {SortCol: string, SortOrder 'asc' | 'desc', Competitors: ICompetitor[], Tasks: TaskModel[]}
                let sortcol = action.payload.SortCol;
                let sortorder = action.payload.SortOrder;
                let comps = action.payload.Competitors as ICompetitor[];
                let tasks = action.payload.Tasks as TaskModel[];

                if (sortcol !== '') {
                    switch (sortcol) {
                        case 'CompetitorID': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ?
                                    ((comps.find(c => c.ID === a.CompetitorID)?.PilotName) ?? '').localeCompare((comps.find(c => c.ID === b.CompetitorID)?.PilotName) ?? '')
                                    :
                                    ((comps.find(c => c.ID === b.CompetitorID)?.PilotName) ?? '').localeCompare((comps.find(c => c.ID === a.CompetitorID)?.PilotName) ?? '')
                            })
                            break;
                        }
                        case 'TaskID': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ?
                                    ((tasks.find(t => t.TaskID === a.TaskID)?.Title) ?? '').localeCompare((tasks.find(t => t.TaskID === b.TaskID)?.Title) ?? '')
                                    :
                                    ((tasks.find(t => t.TaskID === b.TaskID)?.Title) ?? '').localeCompare((tasks.find(t => t.TaskID === a.TaskID)?.Title) ?? '')
                            })

                            break;
                        }
                        case 'FlightDate': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.FlightDate ?? '').localeCompare((b.FlightDate ?? '')) : (b.FlightDate ?? '').localeCompare((a.FlightDate ??''))
                            })
                            break;
                        }
                        case 'LogFile': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.LogFile?.Name ?? '').localeCompare((b.LogFile?.Name ?? '')) : (b.LogFile?.Name ?? '').localeCompare((a.LogFile?.Name ??''))
                            })
                            break;
                        }
                        case 'ScoreResult': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.totalPoints ?? 0) - (b.ScoreResult?.totalPoints ?? 0) : (b.ScoreResult?.totalPoints ?? 0) - (a.ScoreResult?.totalPoints ?? 0)
                            })
                            break;
                        }
                        case 'totalPoints': 
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.totalPoints ?? 0) - (b.ScoreResult?.totalPoints ?? 0) : (b.ScoreResult?.totalPoints ?? 0) - (a.ScoreResult?.totalPoints ?? 0)
                            })
                            break;
                        

                        case 'speed': {
                             draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.AnalysisResult?.speed ?? 0) - (b.AnalysisResult?.speed ?? 0) : (b.AnalysisResult?.speed ?? 0) - (a.AnalysisResult?.speed ?? 0)
                            })
                            break;
                        }
                        case 'totalScoringDistance': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.AnalysisResult?.totalScoringDistance ?? 0) - (b.AnalysisResult?.totalScoringDistance ?? 0) : (b.AnalysisResult?.totalScoringDistance ?? 0) - (a.AnalysisResult?.totalScoringDistance ?? 0)
                            })
                            break;
                        }

                        case 'HCDistance': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.hcDistance ?? 0) - (b.ScoreResult?.hcDistance ?? 0) : (b.ScoreResult?.hcDistance ?? 0) - (a.ScoreResult?.hcDistance ?? 0)
                            })
                            break;
                        }

                        case 'distancePoints': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.distancePoints ?? 0) - (b.ScoreResult?.distancePoints ?? 0) : (b.ScoreResult?.distancePoints ?? 0) - (a.ScoreResult?.distancePoints ?? 0)
                            })
                            break;
                        }

                        case 'hcSpeed': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.hcSpeed ?? 0) - (b.ScoreResult?.hcSpeed ?? 0) : (b.ScoreResult?.hcSpeed ?? 0) - (a.ScoreResult?.hcSpeed ?? 0)
                            })
                            break;
                        }
                        case 'speedPoints': {
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a.ScoreResult?.speedPoints ?? 0) - (b.ScoreResult?.speedPoints ?? 0) : (b.ScoreResult?.speedPoints ?? 0) - (a.ScoreResult?.speedPoints ?? 0)
                            })
                            break;
                        }

                        default:  {
                            
                            draftFlights.sort((a, b) => {
                                return sortorder === 'asc' ? (a[sortcol] ?? '').localeCompare((b[sortcol] ?? '')) : (b[sortcol] ?? '').localeCompare((a[sortcol] ??''))
                            })

                            break;
                        }
                    }
                }
                break;
            }


            default: {
                throw new Error(`Flight Reducer: Unrecognised Action Type ${action.type}`)
            }
        }
    })
}



