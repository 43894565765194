import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';
import { ITurnPoint } from 'ladderscoring';
import { retrieveItems } from '../services/FetchWrapper';
import { useErrorHandler
 } from "react-error-boundary";
 // eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
export interface TurnpointContextData {
    turnpoints: ITurnPoint[];
    addTurnPoint: (tp: ITurnPoint) => void;
    findTurnpoint: (trigraph: string) => ITurnPoint | undefined;
    loading: boolean;
}

//const ENDPOINT = API_ENDPOINTS.TPS;
const ENDPOINT = 'https://api.bgaladder.net/api/TPS/0';

export const TurnpointsContext = createContext<TurnpointContextData>({ turnpoints: [], addTurnPoint: (tp: ITurnPoint) => { }, findTurnpoint:()=> undefined, loading:false  });

export const TurnpointsProvider = ({ children }: { children: ReactNode }) => {
    const [turnpoints, setTurnpoints] = useState<ITurnPoint[]>([])

    const [loading, setLoading] = useState(false);
    const handleError = useErrorHandler();
    
    const addTurnPoint = ((tp: ITurnPoint): void => {
        if (tp) {
            let tps = turnpoints;
            if (tps) {
                tps.push(tp);
                setTurnpoints(tps);
            }
        }
    })

    const findTurnpoint = (trigraph: string) => {
        return turnpoints.find(tp => { return tp.Trigraph === trigraph });
    }

    useEffect(() => {
        const getTurnpoints = async () => {
            //Log(`getTurnpoints`)
            setLoading(true);
            retrieveItems<ITurnPoint>(`${ENDPOINT}/0`, '', true)
            .then( items =>  {
                setTurnpoints(items ?? [])
            })
            .catch(e=>{
                LogError(`getTurnpoints: ${(e as Error).message}`)
                handleError( new Error(`getTurnpoints error ${(e as Error).message}`))
            })
            .finally(()=>{
                setLoading(false)
            });
        }
        getTurnpoints()
    }, 
    // eslint-disable-next-line
    [])

    return (
        <TurnpointsContext.Provider value={{ turnpoints:turnpoints, addTurnPoint: addTurnPoint, findTurnpoint: findTurnpoint, loading:loading}}>
            {children}
        </TurnpointsContext.Provider>
    )
}

export function useTurnpointContext() {
    const context = React.useContext(TurnpointsContext);
    if (context === undefined) {
        throw new Error("useTurnpointContext must be used within a TurnpointProvider");
    }
    return context;
}

export const useTurnpoints = (): TurnpointContextData => {
    const context = useTurnpointContext();    
    return  context
}

export const TurnpointsConsumer = TurnpointsContext.Consumer;
export default TurnpointsContext;