import * as React from "react";
import { useState, useEffect } from 'react';
import {  Typeahead} from 'react-bootstrap-typeahead';

// next import is to fix close button issue betwween TypeAhead V5 & Bootstrap 5
// see https://github.com/ericgio/react-bootstrap-typeahead/issues/622

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'  // this works with Typeahead V6 only
import { Log } from "../../services/Logging";

interface  IGSProps {
    preset?: any[];
    presetid?: any;
    idField: string;
    displayField :  ((option:Object)=>string);
    placeholder?: string;
    backgroundColour?: string;
    options?: any;
    handleSelect(s: any []): any;
    url?: string;
    useOptions?: boolean;
    disabled?: boolean;
}

export default function GenericSelector(props:IGSProps) {
    const [selected, setSelected] = useState(props.preset);
    const [options, setOptions] = useState(props.options);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (props.useOptions) {
            setOptions(props.options);
            setLoading(false);
        }
        else {
            Log(`GenericSelector: fetching from ${props.url}`)
            if (props.url) {
                fetchData(props.url).then(opts => {
                    setOptions(opts);
                    setLoading(false);
                    //Log(`GenericSelector: ${opts.length} options loaded from ${props.url} opts length=${opts.length}`);
                });
            }
            else {
                throw new Error('Generic Selector: if useOptions is false, a url must be provided');
            }
        }
    }, [props.url, props.options, props.useOptions])

    useEffect(() => {
        let s = options.find((i: any) => i[props.idField] === props.presetid);
        //Log(`GenericSelector: handlePreset: Looking for ${props.presetid} in field ${props.idField} in `, options, ` found `, s)
        setSelected((typeof s !== 'undefined') ? [s] : []);
        }, [props.presetid, props.idField,options]);

    function handleSelect(s:any) {
        //Log('GenericSelector: ' + (s[0] ? [props.displayField(s[0])] : 'Nothing') + ' selected');
        setSelected(s);
        props.handleSelect(s);
    }

    function fetchData(url:string) {
        return fetch(url, { })
            .then(resp => resp.json())
            .then(items => {              
                return items;
            });
    }
    return (
        <>
            <Typeahead
                {...props}
                clearButton
                labelKey={props.displayField}
                onChange={handleSelect}
                options={options}
                placeholder={props.placeholder || 'Select a value...'}
                selected={selected}
                isLoading={isLoading}
                id=''
                selectHint={(shouldSelect, event) => (
                    event.key === "Enter" || shouldSelect
                  )}
            >
            </Typeahead>
        </>
    );

}