// User
import { produce } from 'immer';
import { useState,  } from 'react';
import { IToken } from '../Auth/Auth';
import { API_ENDPOINTS, API_URL } from '../Globals';
import { saveFile } from '../lib/SaveFile';
import { deleteItem, post } from '../services/FetchWrapper';
//eslint-disable-next-line
import { Log, LogError } from '../services/Logging';

export interface dbUser extends Record<string, any> {   // this must match UserViewModel on the back end

    ID: number;
    Name: string;
    Forename: string;
    Surname: string;
    Email: string;
    Password?: string ;
    LastLogin? : string
}

export enum UserActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',

    UPDATE_ALL = 'UPDATE_ALL',
    UPDATE_ROW = 'UPDATE_ROW',
    SAVE = 'SAVE',
    LOAD = 'LOAD',
    SORT = 'SORT',
    CLEAR= 'CLEAR',
}

export type UserAction = {
    type: UserActionType;
    payload?: any;
}

export const useUserReducer = (initState: dbUser[]) => {
    const [state, setState] = useState(initState);

    const dispatch = async (action: UserAction) => {
        let newState = await userReducer(state, action);
        setState(newState);
    }
    return [state, dispatch] as const;   // need the 'as const' to get Typescript inferencing working
}

const postUser = async (comp: dbUser, token:IToken) => {
    return post<dbUser>(`${API_URL}/${API_ENDPOINTS.SAVEUSER}`, comp, token)
    .catch(async error => {
        LogError(`Users Update error ${error}`)
    })
}

const deleteUser = async (userid: number|undefined , token:IToken) => {
    if (userid) {
    return deleteItem<dbUser>(`${API_URL}/${API_ENDPOINTS.DELETEUSER}/${userid}`, token)
    .catch(async error => {
        LogError(`Users Delete error ${error}`)
    })
    }
}

export async function userReducer (users: dbUser[], action: UserAction):Promise<dbUser[]> {

    return await produce(users, async (draftUsers:dbUser[]) => {
        //Log(`userReducer: ${action.type} with payload `, action.payload);
        switch (action.type) {

            case UserActionType.INSERT:
                //payload is {value: comp, token: auth?.user?.token}
                let user = action.payload.value as dbUser;
                await postUser(user, action.payload.token)
                    .then(resp=> {
                        if (resp) {
                            let newuser = resp.parsedBody
                            if (newuser) {
                                draftUsers.unshift(newuser);
                            }
                        }
                    })

                break;

            case UserActionType.DELETE: {
                //payload is { ID, token},
                let idx = draftUsers.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    let userid = draftUsers[idx].ID;
                    draftUsers.splice(idx, 1);
                    await deleteUser(userid, action.payload.token)
                }
                break;
            }


            case UserActionType.SAVE: {
                    let json = JSON.stringify({ users: draftUsers });
                    saveFile('Users', json);
                break;
            }

            case UserActionType.LOAD: {
                draftUsers.length = 0;
                let list = action.payload.value as dbUser[]
                list.forEach((user: dbUser) => {
                    draftUsers.push({
                        ID: user.ID,
                        Name: user.Name,
                        Forename: user.Forename,
                        Surname: user.Surname,
                        Email: user.Email,
                        ScorerFor: user.ScorerFor,                        
                    })
                })
                
                break;
            }
            case UserActionType.UPDATE_ROW: {

                //payload is {ID: changedID, value:changedvalue, token: auth?.user?.token},
                let idx = draftUsers.findIndex(c => { return c.ID?.toString() === action.payload.ID.toString() })
                if (idx !== -1) {
                    draftUsers[idx] = { ...draftUsers[idx], ...action.payload.value }
                    postUser(draftUsers[idx], action.payload.token)
                }
                break;
            }
            case UserActionType.UPDATE_ALL: {
                //payload is new Users
                draftUsers.length = 0;
                (action.payload as dbUser[]).forEach(c => draftUsers.push(c));
                break;
            }

            case UserActionType.SORT: {
                //payload is {SortCol: string, SortOrder 'asc' | 'desc'}
                let sortcol:string = action.payload.SortCol;
                let sortorder:string = action.payload.SortOrder;
                if (sortcol !== '') {
                    draftUsers.sort((a, b) => {
                        return sortorder === 'asc' ? a[sortcol].localeCompare(b[sortcol]) : b[sortcol].localeCompare(a[sortcol])
                    })
                }         
                break;
            }
            case UserActionType.CLEAR: {
                draftUsers.length = 0;
                break;
            }

            default:{
                throw new Error(`User Reducer: Unrecognised Action Type ${action.type}`)
                }
        }
    })
}
