// Competition
import { produce } from 'immer';
import { IScoringConfig } from 'ladderscoring';
import {  useState,  } from 'react';
import { IToken } from '../Auth/Auth';
import { API_ENDPOINTS, API_URL } from '../Globals';
import { saveFile } from '../lib/SaveFile';
import { deleteItem, post, } from '../services/FetchWrapper';
// eslint-disable-next-line
import { Log, LogError } from '../services/Logging';

export interface ICompetition extends Record<string, any> {
    ID?: number;
    Title?: string;
    Director?: string ;
    Scorer?: number ;        // ID in Users
    Locked?: boolean ;
    Public?: boolean ;
    Downloads?: boolean ;
    StartDate?: string ;
    EndDate?: string ;
    SiteElevation?: number;
}

export enum CompetitionActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    UPDATE_TITLE = 'UPDATE_TITLE',
    UPDATE_DIRECTOR = 'UPDATE_DIRECTOR',
    UPDATE_SCORER = 'UPDATE_SCORER',
    UPDATE_LOCKED = 'UPDATE_LOCKED',
    UPDATE_START = 'UPDATE_START',
    UPDATE_END='UPDATE_END',
    SAVE_RULES='SAVE_RULES',

    UPDATE_ALL = 'UPDATE_ALL',
    UPDATE_ROW = 'UPDATE_ROW',
    SAVE = 'SAVE',
    LOAD = 'LOAD',
    SORT = 'SORT',
    CLEAR= 'CLEAR',
}

export type CompetitionAction = {
    type: CompetitionActionType;
    payload?: any;
}

export const useCompetitionReducer = (initState: ICompetition[]) => {
    const [state, setState] = useState(initState);

    const dispatch = async (action: CompetitionAction) => {
        let newState = await competitionReducer(state, action);
        setState(newState);
    };
    
    return [state, dispatch] as const;   // need the 'as const' to get Typescript inferencing working
}

const postCompetition = async (comp: ICompetition, token:IToken) => {
    return post<ICompetition>(`${API_URL}/${API_ENDPOINTS.SAVECOMPETITION}`, comp, token)
    .catch(async error => {
        LogError(`Competitions Update error ${error}`)
    })
}


const deleteCompetition = async (compid: number|undefined , token:IToken) => {
    if (compid) {
    return deleteItem<ICompetition>(`${API_URL}/${API_ENDPOINTS.DELETECOMPETITION}/${compid}`, token)
    .catch(async error => {
        LogError(`Competitions Delete error ${error}`)
    })
    }
}

const postRules = async (rules: IScoringConfig, token:IToken) => {
    return post<IScoringConfig>(`${API_URL}/${API_ENDPOINTS.SCORINGCONFIG}`, rules, token)
    .catch(async error => {
        LogError(`Rules Update error ${error}`)
    })
}

export async function competitionReducer (competitions: ICompetition[], action: CompetitionAction):Promise<ICompetition[]> {

    return await produce(competitions, async (draftCompetitions) => {
        //Log(`competitionReducer: ${action.type} with payload `, action.payload);
        switch (action.type) {

            case CompetitionActionType.INSERT:
                //payload is {value: comp, token: auth?.user?.token}
                let comp = action.payload.value as ICompetition;
                await postCompetition(comp, action.payload.token)
                    .then(resp=> {
                        if (resp) {
                            let newcomp = resp.parsedBody
                            if (newcomp) {
                                draftCompetitions.unshift(newcomp);
                            }
                        }
                    })

                break;

            case CompetitionActionType.DELETE: {
                //payload is { ID, token},
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    let compid = draftCompetitions[idx].ID;
                    draftCompetitions.splice(idx, 1);
                    await deleteCompetition(compid, action.payload.token)
                }
                break;
            }
            case CompetitionActionType.UPDATE_TITLE: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].Title = action.payload.value;
                }
                break;
            }
            case CompetitionActionType.UPDATE_DIRECTOR: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].Director = action.payload.value;
                }
                break;
            } 
            case CompetitionActionType.UPDATE_SCORER: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].Scorer = action.payload.value;
                }
                break;
            }

            case CompetitionActionType.UPDATE_LOCKED: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].Locked = action.payload.value;
                }
                break;
            }
            case CompetitionActionType.UPDATE_START: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].StartDate = action.payload.value;
                }
                break;
            }
            case CompetitionActionType.UPDATE_END: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompetitions.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx].EndDate = action.payload.value;
                }
                break;
            }
            case CompetitionActionType.SAVE: {
                    let json = JSON.stringify({ competitors: draftCompetitions });
                    saveFile('Competitions', json);
                break;
            }

            case CompetitionActionType.LOAD: {
                draftCompetitions.length = 0;
                let list = action.payload.value as ICompetition[]
                list.forEach((competition: ICompetition) => {
                    draftCompetitions.push({
                        ID: competition.ID,
                        Title: competition.Title,
                        Director: competition.Director,
                        Scorer: competition.Scorer,
                        Locked: competition.Locked,
                        Public: competition.Public,
                        Downloads: competition.Downloads,
                        StartDate: competition.StartDate,
                        EndDate: competition.EndDate,
                        SiteElevation: competition.SiteElevation,
                    })
                })
                
                break;
            }
            case CompetitionActionType.UPDATE_ROW: {

                //payload is {ID: changedID, value:changedvalue, token: auth?.user?.token},
                let idx = draftCompetitions.findIndex(c => { return c.ID?.toString() === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitions[idx] = { ...draftCompetitions[idx], ...action.payload.value }
                    postCompetition(draftCompetitions[idx], action.payload.token)
                }
                break;
            }
            case CompetitionActionType.UPDATE_ALL: {
                //payload is new Competitions
                draftCompetitions.length = 0;
                (action.payload as ICompetition[]).forEach(c => draftCompetitions.push(c));
                break;
            }

            case CompetitionActionType.SORT: {
                //payload is {SortCol: string, SortOrder 'asc' | 'desc'}
                let sortcol = action.payload.SortCol;
                let sortorder = action.payload.SortOrder;
                if (sortcol !== '') {
                    draftCompetitions.sort((a, b) => {
                        return sortorder === 'asc' ? a[sortcol].localeCompare(b[sortcol]) : b[sortcol].localeCompare(a[sortcol])
                    })
                }         
                break;
            }
            case CompetitionActionType.CLEAR: {
                draftCompetitions.length = 0;
                break;
            }

            case CompetitionActionType.SAVE_RULES: {
                //{value: IScoringConfig, token: auth?.user?.token}
                await postRules(action.payload.value, action.payload.token)
                break;
            }
            default:{
                throw new Error(`Competition Reducer: Unrecognised Action Type ${action.type}`)
                }
        }
    })
}
