
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Draggable from 'react-draggable';
import { DialogSize, DialogType } from './ConfirmModal';
import { useRef } from 'react';


interface IProps{
    keyid?: any;
    show: boolean;
    size?: DialogSize
    header?: JSX.Element;
    headerclass?: DialogType;
    body?: JSX.Element;
    footer?: JSX.Element;
    onClose: ()=>void;
    onOpen?: ()=>void;
}
export default function DraggableModal(props: IProps) {
    const noderRef = useRef(null);
    return (
        <Draggable handle='.modal-header' key={props.keyid} nodeRef={noderRef}>

            <Modal             
                isOpen={props.show}
                onClosed={props.onClose}
                onOpened={props.onOpen}
                fade={false}
                innerRef={noderRef}
                centered
                backdrop='static'
                scrollable
                size={props.size}
                >
                <ModalHeader toggle={()=>{props.onClose()}} className={`hoverdrag ${props.headerclass ? props.headerclass : 'bg-success'}`}>
                    {props.header}
                </ModalHeader>
                <ModalBody className='preserve-breaks'>
                    {props.body}
                </ModalBody>
                <ModalFooter>
                    {props.footer}
                </ModalFooter>
            </Modal>
        </Draggable>
    )
}