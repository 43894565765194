import * as React from 'react';


import { Row, Col, Container, Button, } from 'reactstrap';

import { useState } from 'react';




export default function Test() {
    const [show, setShow] = useState(false)

    return (
        <>
        <Row>
          <Col>
            Test Page
          </Col>
        </Row>
        <Container>
        <Row>
          <Col>
            <Button size='sm' onClick={()=>{
              
              setShow(!show)}}>{
              show ? 'Hide' : 'Show'}</Button>
          </Col>
          <Col>


        </Col>
        </Row>
        </Container>
        </>


    );


}