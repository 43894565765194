import * as React from "react";
import {  useState, useEffect, createContext,  ReactNode } from 'react';
import { TMActionType, TMAction, useTaskReducer, ITaskWithTPs } from '../models/TaskModelReducer';

import { TaskModel } from 'ladderscoring'
import { useCompetitions } from "./CompetitionsContext";
import { useAuth } from "../Auth/AuthProvider";
import { API_ENDPOINTS, API_URL } from "../Globals";
import { retrieveItems } from "../services/FetchWrapper";
import { useTurnpoints } from "./TPDataContext";
import { useErrorHandler } from "react-error-boundary";
import { LogError } from "../services/Logging";
const ENDPOINT = API_ENDPOINTS.COMPTASKS;


export interface TaskContextData {
    tasks: TaskModel[];
    tasksDispatch: (action: TMAction) => Promise<void>;
    dayTasks: (compid: number, datefilter: string | undefined) => TaskModel[]; //datefilter is an ISO Date
    findTask: (taskid: number | undefined, compid: number | undefined) => TaskModel | undefined;
    refresh: ()=>void;
    loading: boolean;
}

const TasksContext = createContext<TaskContextData>(
    {   tasks: [], 
        tasksDispatch: async () => undefined, 
        dayTasks: ()=> [], 
        findTask: ()=>undefined,
        refresh: ()=>undefined,
        loading: false,
    });

export const TasksProvider = ({ children }: { children: ReactNode }) => {

    const auth = useAuth();
    const handleError = useErrorHandler();

    const {turnpoints} = useTurnpoints();

    const [tasks, tasksDispatch] = useTaskReducer([]);
    const {selectedCompetition} = useCompetitions();
        // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [dorefresh, setDoRefresh] = useState(false);

    useEffect(() => {
        const getTasks = async () => {
            // load the tasks & TPs, then construct TaskModels to populate the tasks list...

            let compid = selectedCompetition?.ID ?? '';
            let token = auth?.user?.token ?? null;
            if ( compid !== '') {
                setLoading(true);
                retrieveItems<ITaskWithTPs>(`${API_URL}/${ENDPOINT}/${compid}`, '', true,token)
                .then(async(items)=> {
                    if (items) {
                        await tasksDispatch({
                            type: TMActionType.LOAD_ALL_TASKS,
                            payload: { Tasks: items, Turnpoints: turnpoints,}
                        });
                    }  
                })
                .catch(e=>{
                    LogError(`getTasks: ${(e as Error).message}`)
                    handleError( new Error(`getTasks error ${(e as Error).message}`))
                })
                .finally(()=> {
                    setLoading(false)
                });           
            }
            else {
                // not logged in...
                tasksDispatch({
                    type: TMActionType.CLEAR,
                    payload: { }
                });
            }
        }
        getTasks()
        .catch (e=>{
            LogError(`getTasks: ${(e as Error).message}`)
            handleError(new Error(`getTasks error ${(e as Error).message}`))
        })
        
    }, 
    // supress warning that dispatch function should be in dependencies
// eslint-disable-next-line  
    [ selectedCompetition, auth?.user, turnpoints, dorefresh]);

    const dayTasks = ( compid: number, datefilter: string | undefined) => {
        //datefilter is an ISO Date
        let daytasks = tasks.filter(task => { return (task.TaskDate ?? '') === datefilter && task.CompetitionID===compid })
        return daytasks.sort((a, b) => {return a.TaskDistance - b.TaskDistance})
    }
    const findTask = (taskid: number | undefined, compid: number| undefined) => {
        return tasks.find(t=>t.TaskID===taskid && t.CompetitionID===compid)
    }

    const refresh = () => {
        setDoRefresh(!dorefresh);       // triggers the useEffect that rereads the database
    }
    return (
        <TasksContext.Provider value={{ tasks:tasks, tasksDispatch: tasksDispatch, dayTasks: dayTasks, findTask: findTask, refresh: refresh, loading: loading}}>
            {children}
        </TasksContext.Provider>
    )
}

export function useTasksContext() {
    const context = React.useContext(TasksContext);
    if (context === undefined) {
        throw new Error("useTaskContext must be used within a TaskProvider");
    }
    return context;
}

export const useTasks = (): TaskContextData => {
    const context = useTasksContext();
    return context;
}
export const TasksConsumer = TasksContext.Consumer;

export default TasksContext;