// shared utility routines...
//import moment from 'moment'
import magellan from 'magellan'
import { DateTime } from 'luxon';


export class Utilities {


static isErrorJson(data) {
    // checks if data can be parsed, if not returns true...
    try {
        // eslint-disable-next-line
        let a = JSON.parse(data);
        return false;
    } catch (e) {
        return true;
    }
}

static htmlEscape(str) {
    return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\//g, '&#x2F;');

}

static htmlUnescape(str) {
    return str
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&#x2F;/g, '/');

}



/**  Get the (approximate) heading in degrees between two points
 * @param { object } fromPoint - origin point with Latitude & Longitude properties
 * @param { object } toPoint - destination point with Latitude & Longitude properties
 * @returns { double }  heading in degrees
 */
static getHeading(fromPoint, toPoint) {
    // get the (approximate) heading in degrees between two points
    // simple calc for speed
    var heading = 0;
    if (fromPoint && toPoint) {
        var latA = fromPoint.Latitude * Math.PI / 180;
        var lonA = fromPoint.Longitude * Math.PI / 180;
        var latB = toPoint.Latitude * Math.PI / 180;
        var lonB = toPoint.Longitude * Math.PI / 180;
        heading = 180 * (Math.atan2(Math.sin(lonB - lonA) * Math.cos(latB), Math.cos(latA) * Math.sin(latB) - Math.sin(latA) * Math.cos(latB) * Math.cos(lonB - lonA)) % (2 * Math.PI)) / (Math.PI);
    }
    return heading;
}

/**  Format a Lat/Long for display
 * @param { object } point - point with Latitude & Longitude properties
 * @param {boolean } decimal - optional flag to use decimal degrees
 * @returns { string }  Position in DD MM.MMM or DD.DDD format
 */
static formatPosition(point, decimal) {
    var result = 'Invalid Position';
    if (point && point.Latitude && point.Longitude && !isNaN(point.Latitude) && !isNaN(point.Longitude)) {
        result = Utilities.formatLatLng(point.Latitude, point.Longitude, decimal);
    }
    return result;
}
/**  Format a Lat/Long for display
 * @param { number } latitude - the latitude
 * @param {number } longitude - the logitude
 * @param {boolean } decimal - optional flag to use decimal degrees
 * @returns { string }  Position in DD MM.MMM or DD.DDD format
 */
static formatLatLng(latitude, longitude, decimal) {
    var result = 'Invalid Position';
    if (latitude && longitude && !isNaN(latitude) && !isNaN(longitude)) {
        result = Utilities.formatLat(latitude, decimal) + ' ' + Utilities.formatLng(longitude, decimal);
    }
    return result;
}
/**  Format a Latitude for display
 * @param { number } latitude - the latitude
 * @param {boolean } decimal - optional flag to use decimal degrees
 * @returns { string }  Position in DD MM.MMMH or DD.DDD format
 */
static formatLat(latitude, decimal) {
    var result = '';
    if (latitude && !isNaN(latitude)) {
        if (decimal) {
            result = latitude.toFixed(3);
        }
        else {
            var latdegrees = Math.abs(latitude);
            var latdegreepart = Math.floor(latdegrees);
            var latminutepart = 60 * (latdegrees - latdegreepart);
            result = latdegreepart.toLocaleString('en', { minimumIntegerDigits: 2, minimumFractionDigits: 0, useGrouping: false })
                + ' '
                + latminutepart.toLocaleString('en', { minimumIntegerDigits: 2, minimumFractionDigits: 3, useGrouping: false })
                + ((latitude > 0) ? "N" : "S");
        }
    }
    return result;
}
/**  Format a Longitude for display
 * @param { number } longitude - the longitude
 * @param {boolean } decimal - optional flag to use decimal degrees
 * @returns { string }  Position in DD MM.MMMH or DD.DDD format
 */
static formatLng(longitude, decimal) {
    var result = '';
    if (longitude && !isNaN(longitude)) {
        if (decimal) {
            result = longitude.toFixed(3);
        }
        else {
            var lngdegrees = Math.abs(longitude);
            var lngdegreepart = Math.floor(lngdegrees);
            var lngminutepart = 60 * (lngdegrees - lngdegreepart);
            result = lngdegreepart.toLocaleString('en', { minimumIntegerDigits: 2, minimumFractionDigits: 0, useGrouping: false })
                + ' '
                + lngminutepart.toLocaleString('en', { minimumIntegerDigits: 2, minimumFractionDigits: 3, useGrouping: false })
                + ((longitude > 0) ? 'E' : 'W');
        }
    }
    return result;
}

/** Unformat a Latitude or Longitude
 * @param { string } value - Position in DD MM.MMMH or DD.DDD format
 * @param { boolean } decimal - optional flag to use decimal degrees
 * @returns { number }  decimal value or NaN
 */
static unformatLatLng(value, decimal) {
    var result = NaN;
    if (decimal) {
        result = parseFloat(value);
    }
    else {
        // its in DD MMM.MMMH format
        var hemi = value.slice(-1); // get hemisphere character off the end
        var parts = value.slice(0, -1).split(' ');
        if (parts.length === 2) {
            result = (parseInt(parts[0] + parseFloat(parts[1]) / 60)) * (hemi === 'S' || hemi === 'W' ? -1 : 1);
        }
    }
    return result;
}

/**
 * Parse a string for Latitude
 * 
 * @param {string} value - the string to parse
 * @return {object} - object with properties lat, valid and error
 */
static parseLatitude(value) {
    var result = {
        lat: NaN,
        valid: false,
        error: ''
    };
    var lat = magellan(value).latitude();
    if (lat === null) {
        result.error = 'Could not understand ' + value + ' as a Latitude';
    }
    else {
        result.lat = lat.toDD();
        result.valid = true;
    }
    return result;
}

/**
 * Parse a string for Longitude
 * 
 * @param {string} value - the string to parse
 * @return {object} - object with properties lng, valid and error
 */
static parseLongitude(value) {
    var result = {
        lng: NaN,
        valid: false,
        error: ''
    };

    var lng = magellan(value).longitude();
    if (lng === null) {
        result.error = 'Could not understand ' + value + ' as a Longitude';
    }
    else {
        result.lng = lng.toDD();
        result.valid = true;
    }
    return result;
}

/**
 *  Get the ViewPort Width
 * @return {number} The Viewport width in pixels
 */

static viewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

/** determine if we are on a device with a width below the Bootstrap4 'sm' size
 * @return {boolean} - true if we are on a small device
 * 
 */
static isPhone() {
    var mq = window.matchMedia("(min-width: 576px)");
    return !mq.matches;
}

/** Extract the flight date from a blob containing a logger file
 * @param {object} file - a File blob 
 * @returns {Promise} - which will resolve with the date, or null
 */
    static getFlightDate(file) {
        //extract the flight date from a logger file...
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.onload = (e) => {
                resolve(this.getFlightDateFromText(reader.result));
            }
            reader.readAsText(file);

        });
    }
    /** Extract the flight date from a string containing a logger file
     * @param {string} file - a string with the contents of a logger file
     * @returns {date} - the date, or undefined
     */
    static getFlightDateFromText(text) {
        let loggerflightdate = undefined;
        if (text && text.length > 0) {
            let extractDate = text.match(/H[FPO]DTE(?:DATE:)?([\d]{6})/);
            if (extractDate) {            
                loggerflightdate = DateTime.fromFormat(extractDate[1], 'ddMMyy').toJSDate();
            }
        }
        return loggerflightdate;
    }



/**
 * Extract hours from a time in seconds
 * @param {number | undefined | null} time - a time in seconds
 * @returns {number} - the number of hours in the time, or undefined
 */
    static getHours = (time) => { 
        if (time === null || time === undefined) return undefined;
        return Math.floor(time / 3600);
    }
/**
 * Extract minutes from a time in seconds
 * @param {number | undefined | null} time - a time in seconds
 * @returns {number } - the number of minutes in the time, or undefined
 */
    static getMinutes = (time) => {
        if (time === null || time === undefined) return undefined;
        return Math.floor((time - this.getHours(time)*3600)/60)
    }
    /**
 * Extract seconds from a time in seconds
 * @param {number | undefined | null} time - a time in seconds
 * @returns {number} - the number of seconds in the time, or undefined
 */
    static getSeconds = (time) => {
        if (time === null || time === undefined) return undefined;
        return time - (this.getHours(time) * 3600) - (this.getMinutes(time) * 60);
    }
}