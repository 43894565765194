import * as React from "react";

import { FormEvent, useState } from 'react';
import { Navigate,} from 'react-router-dom';
import { Form, FormGroup, Label, Input, Button, Card, CardBody} from 'reactstrap';
import { useWarningDialog } from "../Context/ConfirmDialogContext";

import { useAuth } from './AuthProvider';

import DraggableModal from "../components/Utility/DraggableModal";
import { DialogType } from "../components/Utility/ConfirmModal";


export default function Login() {

    const auth = useAuth();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [useladder, setUseLadder] = useState(false);
    const [loginenabled, setLoginenabled] = useState(true);
    
    const [modalOpen, setModalOpen] = useState(true);
    const {showWarning} = useWarningDialog();
    
   const handleSubmit = async (e:FormEvent) => {
       e.preventDefault();
       setLoginenabled(false);
       auth?.signOut();
        let loginok = await auth?.signIn({ username: username, password: password, useladder: useladder });
        setLoginenabled(true)
        setModalOpen(false);
        if (!loginok) {
            await showWarning({title: 'Login Failed', message:'Login failed. Please try again'})
            setModalOpen(true);
        }
    }

    return (
        auth?.isAuthenticated() ?
            (
                <Navigate to="/"></Navigate>
            )
            :
            (
                <DraggableModal 
                    show={modalOpen}
                    onClose={() => { setModalOpen(false) }}
                    header={<><span>Please Log in</span><span className='textSmall'> (only required for Scorers)</span></>}
                    headerclass={DialogType.white}
                    body = {
                        <Card>
                        <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label>Email address</Label>
                                        <Input type="email" placeholder="Enter email" onChange={e => setUserName(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Password</Label>
                                        <Input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>Use Ladder login?</Label>
                                        <Input type="checkbox"  onChange={e=>setUseLadder(e.target.checked)} />
                                    </FormGroup>
                                    <Button variant="primary" type="submit" className="float-end" disabled={!loginenabled}>
                                        {loginenabled ? 'Log In' : 'Wait...'}
                                    </Button>
                                </Form>

                        </CardBody>
                    </Card>                        
                    }
                />
                )
        )

}