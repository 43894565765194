import * as React from "react";
import {  useState, useEffect, createContext,  ReactNode } from 'react';
import { useAuth } from "../Auth/AuthProvider";
import { API_ENDPOINTS, API_URL, STANDARD_PRESSURE } from "../Globals";
import { post, retrieveItems } from "../services/FetchWrapper";
import { useCompetitions } from "./CompetitionsContext";
import { useErrorHandler } from "react-error-boundary";
// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
import { IHeights } from "../models/Heights";


export interface dbHeights {
    CompetitionID: number;
    Date: string;       //ISO Date
    MaxStartHeight?: number;
    MinFinishHeight?: number;
    QNH?: number;
}
export interface HeightsContextData {
    heights: dbHeights[];
    findHeights: (compid: number | undefined, date: string) => IHeights | undefined;
    saveHeights: (compid: number, date: string, wind:IHeights) => void;
}


const HeightsContext = createContext<HeightsContextData>(
    {
        heights: [],
        findHeights: () => undefined,
        saveHeights: () => undefined,
    });

const ENDPOINT = API_ENDPOINTS.HEIGHTS;

export const HeightsProvider = ({ children }: { children: ReactNode }) => {

    const [heights, setHeights] = useState<dbHeights[]>([]);

    const auth = useAuth();  
    const {selectedCompetition} = useCompetitions();
    const handleError = useErrorHandler()
    useEffect(() => {

        const getHeights = async () => {

            let compid = selectedCompetition?.ID ?? '';
            //Log(`getHeights for ${compid}`)
            //let userid = auth?.user?.userDetails?.userid ?? '';
            let token = auth?.user?.token ?? null;
            if (compid !== '') {
                //Log(`getHeights from ${API_URL}/${ENDPOINT}/${compid}`)

                retrieveItems<dbHeights>(`${API_URL}/${ENDPOINT}/${compid}`, '', true,token)
                .then( async (items) =>  {
                    Log(`getHeights: `, items)
                    if (items) {                  
                        setHeights(items);
                    }
                })
                .catch(e=>{
                    LogError(`getHeights: ${(e as Error).message}`)
                    handleError(new Error(`getHeights error ${(e as Error).message}`))
                })             
            }
            else {
                // not logged in...
                setHeights([]);
            }
        }
        getHeights()
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user, selectedCompetition]);


    const findHeights = (compid: number | undefined, date: string):IHeights|undefined => {
        let height = heights.find(w=>w.CompetitionID===compid && w.Date===date)
        return height ? {MaxStartHeight: height.MaxStartHeight, MinFinishHeight: height.MinFinishHeight, QNH: height.QNH ?? STANDARD_PRESSURE} : undefined;
    }

    const saveHeights = async (compid: number, date: string, height:IHeights) => {
        if (compid !== 0 && height) {
            let dbh:dbHeights = {CompetitionID: compid, Date: date, MaxStartHeight: height.MaxStartHeight, MinFinishHeight: height.MinFinishHeight, QNH: height.QNH ?? STANDARD_PRESSURE}
            let newHeights = [...heights];
            let idx = heights.findIndex(h=>h.CompetitionID===compid && h.Date===date);
            if (idx !== -1) {
                // we have existing data to overwrite
                newHeights[idx].MaxStartHeight=dbh.MaxStartHeight;
                newHeights[idx].MinFinishHeight=dbh.MinFinishHeight;
            }
            else {
                // add a new entry
                newHeights.push(dbh);
            }
            setHeights(newHeights);
            return post<dbHeights>(`${API_URL}/${API_ENDPOINTS.HEIGHTS}`, dbh,auth?.user?.token)
        }
    }

    return (
        <HeightsContext.Provider value={
            {
                heights: heights,
                findHeights: findHeights,
                saveHeights: saveHeights,

            }}>
            {children}
        </HeightsContext.Provider>
    )
}

export function useHeightsContext() {
    const context = React.useContext(HeightsContext);
    if (context === undefined) {
        throw new Error("useHeightsContext must be used within a HeightsProvider");
    }
    return context;
}

export const useHeights = (): HeightsContextData=> {
    const context = useHeightsContext();
    return context;
}
export const CompetitorsConsumer = HeightsContext.Consumer;

export default HeightsContext;