import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';
import { useAuth } from "../Auth/AuthProvider";
import {  retrieveItems } from "../services/FetchWrapper";
import { API_ENDPOINTS, API_URL } from "../Globals";

import { useErrorHandler } from 'react-error-boundary';

// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";

import { CompClassAction, CompClassActionType, ICompClass, useCompClassReducer } from "../models/CompClasses";
import { useCompetitors } from "./CompetitorsContext";

export interface CompClassesContextData {
    compclasses: ICompClass[];
    compclassesDispatch: (action: CompClassAction) => void;
    findCompClass: (id?:  number) => ICompClass | undefined;
    competitorClass: (id: number, compid?: number) => ICompClass | undefined;
    findCompClasses: (id?:number) => ICompClass[] 

    loading:boolean;
}
const ENDPOINT = API_ENDPOINTS.COMPCLASSES;

const CompClassesContext = createContext<CompClassesContextData>(
    { 
        compclasses: [],
        compclassesDispatch: () => undefined,
        findCompClass: () => undefined,
        competitorClass: () => undefined,
        findCompClasses: () => [],

        loading:false,

});

export const CompClassesProvider = ({ children }: { children: ReactNode }) => {
    const [compclasses, compclassesDispatch] = useCompClassReducer([])
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const { findCompetitor} = useCompetitors();

    const handleError = useErrorHandler()
    useEffect(() => {

        const getCompClasses = async () => {
            let token = auth?.user?.token ?? null;

            //Log(`getCompClasses from ${API_URL}/${ENDPOINT}`)
            setLoading(true);
            retrieveItems<ICompClass>(`${API_URL}/${ENDPOINT}`, '', true,token)
            .then(async (items)=> {
                if (items) {
                    await compclassesDispatch({
                        type: CompClassActionType.LOAD,
                        payload: { value: items }
                    }); 
                }
            })
            .catch(e=>{
                LogError(`getCompClasses: ${(e as Error).message}`)
                handleError(new Error(`getCompClasses error ${(e as Error).message}`));
            })
            .finally(()=> {setLoading(false)});          

        }
        getCompClasses()
        .catch(e=>{ handleError(new Error(`getCompClasses error ${(e as Error).message}`))})
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user?.token,]);

    const competitorClass = (competitorid:number, compid?:number) => {
        //Log(`competitorClass: Looking up id ${competitorid} in comp ${compid} with competitors=`, competitors)

        let c = findCompetitor(competitorid, undefined);
        let cls = c ? findCompClass(c.CompClassID) : undefined;
        //Log(`competitorClasss: Comp ${compid} Competitor ${competitorid} name ${c?.PilotName} has classid ${c?.CompClassID}, class ${cls?.Title}`)
        return cls;
    }

    const findCompClass = (classid: number | undefined) => {
        let cls = compclasses.find(c=> { return c.ID === classid });
        return cls;
    }
    const findCompClasses = (compid?:number) => {
        let filteredclasses:ICompClass[] = [];
        if (compclasses) {
            filteredclasses = compclasses.filter(c=>{return c.CompetitionID===compid}).sort((a,b) => {return a.Seq-b.Seq})
        }        
        return filteredclasses
    }

    return (
        <CompClassesContext.Provider value={
            {
                compclasses: compclasses,
                compclassesDispatch: compclassesDispatch,
                findCompClass: findCompClass,
                findCompClasses: findCompClasses,
                competitorClass: competitorClass,                
                loading: loading,
            }}>
            {children}
        </CompClassesContext.Provider>
    )
}

export function useCompClassesContext() {
    const context = React.useContext(CompClassesContext);
    if (context === undefined) {
        throw new Error("useCompClassesContext must be used within a CompClassesProvider");
    }
    return context;
}

export const useCompClasses = (): CompClassesContextData=> {
    const context = useCompClassesContext();
    return context;
}
export const CompetitionsConsumer = CompClassesContext.Consumer;

export default CompClassesContext;

