// User profile management
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from '../../../Auth/AuthProvider';
import { useUsers } from '../../../Context/UsersContext';
import { dbUser, UserActionType } from '../../../models/User';
import UserProfileDialog from './UserProfileDialog';


export default function Profile() {

    const auth = useAuth();
    const navigate = useNavigate();

    const { findUser, usersDispatch,} = useUsers();
    const [user, setUser] = useState<dbUser>();
    const [showprofile, setShowprofile] = useState(true);

    useEffect(()=>{
        setUser(findUser(auth?.user?.userDetails?.userid ?? 0))
    },
    // ignore dependency on findUser
    // eslint-disable-next-line 
    [auth?.user])

    const close = () => {
        setShowprofile(false);
        navigate('/') ;
    }
    return (
              user ?                         
                <UserProfileDialog 
                    show={showprofile}
                    user={user}
                    handleHide={ ()=>{
                        close()
                    }}
                    handleSave={(user:dbUser) => {
                        usersDispatch({
                            type: UserActionType.UPDATE_ROW,
                            payload: {ID: user.ID, value:user, token: auth?.user?.token},
                        });
                        close();
                    }}
                />
             :
                    <>
                        <span className='h3'>The User details cannot be found</span>
                    </>
        )
}