import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';
import { useAuth } from "../Auth/AuthProvider";
import {  retrieveItems } from "../services/FetchWrapper";
import { API_ENDPOINTS, API_URL } from "../Globals";

import { dbUser, UserAction, UserActionType, useUserReducer } from "../models/User";
import { useErrorHandler } from "react-error-boundary";
import { LogError } from "../services/Logging";

export interface UsersContextData {
    users: dbUser[];
    usersDispatch: (action: UserAction) => void;
    sortedUsers: () => dbUser[];
    findUser:(id:number) => dbUser | undefined;
    findUserByEmail:(email:string) => dbUser | undefined;
    userName:(id: number | undefined) => string;
    loading: boolean;

}
const ENDPOINT = API_ENDPOINTS.USERS;

const UsersContext = createContext<UsersContextData>(
    {
        users: [],
        usersDispatch: () => undefined,
        sortedUsers: () => [],
        findUser: ()=>undefined,
        findUserByEmail: ()=>undefined,
        userName: ()=>'',
        loading:false,
});

export const UsersProvider = ({ children }: { children: ReactNode }) => {
    const [users, usersDispatch] = useUserReducer([])

    const [loading, setLoading] = useState(false);
    const auth = useAuth();        
    const handleError = useErrorHandler();

    useEffect(() => {

        const getUsers = async () => {
            let token = auth?.user?.token ?? null;
            if (token) {
                retrieveItems<dbUser>(`${API_URL}/${ENDPOINT}/0`, '', true,token)
                .then(items=> {
                    if (items) {
                        usersDispatch({
                            type: UserActionType.LOAD,
                            payload: { value: items }
                        });
                    }
                })
                .catch(e=> {
                    LogError(`getUsers: ${(e as Error).message}`)
                    handleError( new Error(`getUsers error ${(e as Error).message}`))
                })
                .finally(()=>{setLoading(false)});
            }
        }
        getUsers()
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user?.token,]);

    const sortedUsers = () => {
        return [...users].sort((c1, c2) => { return (c1?.Surname ?? '').localeCompare((c2?.Surname ?? '')) })
    }

    const findUser = (id:number) => {
        return users.find(u=>u.ID===id);
    }
    const findUserByEmail = (email:string) => {
        return users.find(u=>u.Email===email);
    }

    const userName = (id: number | undefined) => {
        let user =  users.find(u=>u.ID.toString()===id?.toString())
        return user ? user.Forename + ' ' + user.Surname : '(Unknown User)';
    }

    return (
        <UsersContext.Provider value={
            {
                users: users,
                usersDispatch: usersDispatch,
                sortedUsers: sortedUsers,
                findUser: findUser,
                findUserByEmail: findUserByEmail,
                userName: userName,
                loading:loading,

            }}>
            {children}
        </UsersContext.Provider>
    )
}

export function useUsersContext() {
    const context = React.useContext(UsersContext);
    if (context === undefined) {
        throw new Error("useUsersContext must be used within a UsersProvider");
    }
    return context;
}

export const useUsers = (): UsersContextData=> {
    const context = useUsersContext();
    return context;
}
export const UsersConsumer = UsersContext.Consumer;

export default UsersContext;

