import 'bootstrap/dist/css/bootstrap.css';
import './css/site.css'

import React from 'react';
import {createRoot} from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import "react-datepicker/dist/react-datepicker.css";
import 'dx-react-grid-bootstrap5/dist/dx-react-grid-bootstrap5.css';

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href');
const rootElement = document.getElementById('root');
<link href="/open-iconic/font/css/open-iconic-bootstrap.css" rel="stylesheet"></link>

const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>);


