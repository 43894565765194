import * as React from "react";
import {  useState, useEffect, createContext,  ReactNode } from 'react';
import { CompetitorAction, CompetitorActionType,  ICompetitor, useCompetitorReducer } from "../models/Competitor";
import { useAuth } from "../Auth/AuthProvider";
import { API_ENDPOINTS, API_URL } from "../Globals";
import { retrieveItems } from "../services/FetchWrapper";
import { useCompetitions } from "./CompetitionsContext";
import { useErrorHandler } from "react-error-boundary";
// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
export interface CompetitorsContextData {
    competitors: ICompetitor[];
    competitorsDispatch: (action: CompetitorAction) => void;
    findCompetitor: (id: number, compid: number | undefined) => ICompetitor | undefined;
    sortedCompetitors: () => ICompetitor[];
    compCompetitors: (compid: number) => ICompetitor[];
    reload: ()=>void;
    loading: boolean;
}


const CompetitorsContext = createContext<CompetitorsContextData>(
    {
        competitors: [],
        competitorsDispatch: () => undefined,
        findCompetitor: () => undefined,
        sortedCompetitors: () => [],
        compCompetitors: () => [],
        loading:false,    
        reload: ()=> undefined,
    });
const ENDPOINT = API_ENDPOINTS.COMPETITORS;

export const CompetitorsProvider = ({ children }: { children: ReactNode }) => {
    const [competitors, competitorsDispatch] = useCompetitorReducer([])
        // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const auth = useAuth();  
    const {selectedCompetition} = useCompetitions();
    const handleError = useErrorHandler()

    const [flag, setFlag] = useState(false);

    useEffect(() => {

        const getCompetitors = async () => {

            let compid = selectedCompetition?.ID ?? '';
            //let userid = auth?.user?.userDetails?.userid ?? '';
            let token = auth?.user?.token ?? null;
            if (compid !== '') {
                //Log(`getCompetitors from ${API_URL}/${ENDPOINT}/${compid}`)
                setLoading(true);
                retrieveItems<ICompetitor>(`${API_URL}/${ENDPOINT}/${compid}`, '', true,token)
                .then( async (items) =>  {
                    if (items) {
                        await competitorsDispatch({
                            type: CompetitorActionType.LOAD,
                            payload: { value: items }
                        });
                    }
                })
                .catch(e=>{
                    LogError(`getCompetitors: ${(e as Error).message}`)
                    handleError(new Error(`getCompetitors error ${(e as Error).message}`))
                })
                .finally(()=>{
                    setLoading(false)
                });
             
            }
            else {
                // not logged in...
                competitorsDispatch({
                    type: CompetitorActionType.CLEAR,
                    payload: { }
                });
            }
        }
        getCompetitors()
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user, selectedCompetition, flag]);


    const findCompetitor = (id: number, competitionid: number | undefined) => {
        
        let comp:ICompetitor | undefined= undefined
        if (id !== undefined) {
            //Log (`findCompetitor: Looking up id ${id} in `, competitors)
            comp = competitors.find(c=> { return c.ID === id && (competitionid ? c.CompetitionID===competitionid : true) });
        }
        return comp;
    }
    const sortedCompetitors = () => {
        return [...competitors].sort((c1, c2) => { return (c1?.PilotName ?? '').localeCompare((c2?.PilotName ?? '')) })
    }

    const compCompetitors = (compid: number)=> {
        return competitors.filter(c=>c.CompetitionID===compid);
    }
    const reload = ()=> { setFlag(!flag)}

    return (
        <CompetitorsContext.Provider value={
            {
                competitors: competitors,
                competitorsDispatch: competitorsDispatch,
                findCompetitor: findCompetitor,
                sortedCompetitors: sortedCompetitors,
                compCompetitors: compCompetitors,
                loading: loading,
                reload: reload,

            }}>
            {children}
        </CompetitorsContext.Provider>
    )
}

export function useCompetitorsContext() {
    const context = React.useContext(CompetitorsContext);
    if (context === undefined) {
        throw new Error("useCompetitorsContext must be used within a CompetitorsProvider");
    }
    return context;
}

export const useCompetitors = (): CompetitorsContextData=> {
    const context = useCompetitorsContext();
    return context;
}
export const CompetitorsConsumer = CompetitorsContext.Consumer;

export default CompetitorsContext;