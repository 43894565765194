import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';

// eslint-disable-next-line
import { Log,  } from "../services/Logging";
import {DateTime}  from 'luxon';
import { useCompetitions } from "./CompetitionsContext";


export interface TimeZoneContextData {
    timeoffset: number;
}
const TimeZoneContext = createContext<TimeZoneContextData>(
    { 
        timeoffset: 0
    });

export const TimeZoneProvider = ({ children }: { children: ReactNode }) => {

    const [timeoffset, setTimeoffset] = useState<number>(0);           // in seconds from UTC
    const { selectedCompetition } = useCompetitions();
    useEffect(() => {

        const getTimeZone =  () => {
           
            let dt = selectedCompetition?.StartDate;
            if (dt) {
                let time = DateTime.fromISO(dt);
                //Log(`getTimeZone: Comp Start is ${time.toISO()}, offset ${time.zone.offset(time.toMillis())}, zone is `, time.zone)
                setTimeoffset(time.zone.offset(time.toMillis()));
            }
        }
        getTimeZone()
    }, 
  
    [ selectedCompetition]);

    return (
        <TimeZoneContext.Provider value={
            {
                timeoffset: timeoffset
            }}>
            {children}
        </TimeZoneContext.Provider>
    )
}

export function useTimeZoneContext() {
    const context = React.useContext(TimeZoneContext);
    if (context === undefined) {
        throw new Error("useTimeZoneContext must be used within a TimeZoneProvider");
    }
    return context;
}

export const useTimeZone = (): TimeZoneContextData=> {
    const context = useTimeZoneContext();
    return context;
}
export const CompetitionsConsumer = TimeZoneContext.Consumer;

export default TimeZoneContext;

